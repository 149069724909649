<template>
	<div class="user-image-container">
		<img class="integrant-pics" :class="props.size" :src="imageSrc" :alt="'Imagem de perfil de ' + props.name " :title="props.big ? '' : props.name">
		<template v-if="props.big">{{ props.name }}</template>
	</div>
</template>

<script setup lang="ts">

import { PropType, defineProps, ref, watchEffect } from "vue";
import placeholderBroken from "/src/assets/images/broken-img.svg";

type ImgSize =
	"small"
	| "medium"
	| "large"
	| "huge"

const props = defineProps({
	imgUrl: {
		type: String,
		required: true,
	},
	name: {
		type: String,
		required: true,
	},
	big: {
		type: Boolean,
		default: false,
	},
	size: {
		type: String as PropType<ImgSize>,
	},
	placeholder: {
		type: String,
		default: placeholderBroken,
	},
});

const imageSrc = ref(props.placeholder);
const img = new Image();

img.onload = () => {
	imageSrc.value = props.imgUrl;
};
img.onerror = () => {
	imageSrc.value = placeholderBroken;
};

watchEffect(() => {
	if (props.imgUrl?.length !== 0)
		img.src = props.imgUrl;
	else
		imageSrc.value = props.placeholder;
});

</script>

<style lang="scss">

@import "@/styles/Variables.scss";

.user-image-container {
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

.integrant-pics {
	width: 2rem;
	aspect-ratio: 1;
	border-radius: 50%;
	background-color: $ot-background-secondary;

	&.small {
		width: 1.4rem;
		height: 1.4rem;
	}

	&.medium {
		width: 1.8rem;
	}

	&.large {
		width: 2.5rem;
	}

	&.huge {
		width: 10rem;
	}

	&.more {
		background-color: $ot-background-secondary;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&:after {
		font-size: 1.2rem;
		content: "···";
		font-weight: 600;
		display: grid;
		place-content: center;
		color: $ot-text-primary;
	}
}


.team-members-list {
	.radio-custom-label {
		label {
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}
	}
}

.tdUserImage {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

body:has(#app > main.dark) {
	.integrant-pics.more:after {
		color: $ot-dark-text-secondary;
	}
}

</style>
